import { useState, useEffect } from "react"
import { Auth } from "aws-amplify"
import { useQuery } from "react-query"
import Users from "./apis/users"
import { queryClient } from "./functions.utils"

/**
|--------------------------------------------------
| @isAuthenticated => for getting user state
| @useAuthStatus => custom HOOK for checking authorize state and loading status
|--------------------------------------------------
*/

const isAuthenticatedAsync = async () => {
  try {
    const response = await Auth.currentAuthenticatedUser()
    return response
  } catch (error) {
    return null
  }
}

const useAuthStatus = () => {
  const [authorize, setAuthorize] = useState(false),
    [emailVerified, setEmailVerified] = useState(false),
    [loading, setLoading] = useState(true),
    { status, data, isFetching } = useQuery("useAuthStatus", isAuthenticatedAsync, {
      refetchOnWindowFocus: false,
    })

  useEffect(() => {
    setLoading(true)
    if (!isFetching && status === "success") {
      if (data) {
        setAuthorize(true)
        if (data.attributes) {
          if (data.attributes.email_verified) {
            setEmailVerified(true)
          } else {
            setEmailVerified(false)
          }
        }
      } else if (!data) {
        setAuthorize(false)
      }
      setLoading(false)
    }
  }, [data, status, isFetching])
  return [authorize, loading, emailVerified]
}

const verifyUserPassword = async (email, password) => {
  let response = await Auth.signIn(email, password)
  return response
}

const signIn = async ({ email, password }) => {
  const response = await Auth.signIn(email, password)
  return response
}

const signUp = async ({ fullname, email, password }) => {
  return await Auth.signUp({
    username: email,
    password,
    attributes: {
      name: fullname.trim(),
      email,
    },
  })
}

const createUser = async (email, password, subscriptionPlan = "free", timezone) => {
  const signInResponse = await Auth.signIn(email, password)
  await Users.create({
    fullname: signInResponse.attributes.name,
    email,
    user_id: signInResponse.attributes.sub,
    subscription_plan: subscriptionPlan,
    default_timezone: timezone,
  })
  return signInResponse
}

const changeEmail = async (formData) => {
  const user = await Auth.currentAuthenticatedUser()
  await Auth.updateUserAttributes(user, { email: formData.email })
  return user.attributes.email
}

const resendVerificationCode = async () => {
  await Auth.verifyCurrentUserAttribute("email")
}

const updateEmailVerificationStatus = async (emailStatus) => {
  const user = await Auth.currentAuthenticatedUser()
  await Auth.updateUserAttributes(user, { email_verified: emailStatus })
}

const changeFullName = async (formData) => {
  const user = await Auth.currentAuthenticatedUser()
  await Auth.updateUserAttributes(user, { name: formData.fullname })
}

const signOut = async () => {
  await Auth.signOut()
  queryClient.invalidateQueries("useAuthStatus")
}

const getAccessToken = async () => {
  let accessToken
  await Auth.currentSession().then((res) => {
    accessToken = res.getAccessToken().getJwtToken()
  })
  return accessToken
}

export {
  isAuthenticatedAsync,
  useAuthStatus,
  signIn,
  signUp,
  signOut,
  changeEmail,
  resendVerificationCode,
  changeFullName,
  updateEmailVerificationStatus,
  verifyUserPassword,
  getAccessToken,
  createUser,
}
