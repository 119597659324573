import { QueryClient } from "react-query"

export const capitalize = (s) => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const areEqual = (array1 = [], array2 = []) => {
  if (array1.length !== array2.length) return false
  array1.sort()
  array2.sort()
  return array1.every((element, index) => JSON.stringify(element) === JSON.stringify(array2[index]))
}

// SOURCE => https://stackoverflow.com/a/34796988/9183715
export const round = (value, decimals) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals).toFixed(decimals)
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: 5 * 60 * 1000,
    },
  },
})

export const downloadCSV = (id, integrationName) => {
  let csv_data = []
  const table = document.getElementById(id).querySelector("table")
  const rows = table.querySelectorAll("tr")
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].classList.contains("row-opened")) {
      continue
    } else {
      let cols = rows[i].querySelectorAll("td,th")
      let csvrow = []
      for (let j = 0; j < cols.length; j++) {
        let rowData = cols[j].innerHTML
        let htmlRemoved = rowData.replace(/<\/?[^>]+(>|$)/g, "")
        csvrow.push(htmlRemoved)
      }
      csv_data.push(csvrow.join(","))
    }
  }
  csv_data = csv_data.join("\n")
  let CSVFile = new Blob([csv_data], { type: "text/csv" })
  var temp_link = document.createElement("a")
  temp_link.download = integrationName + ".csv"
  var url = window.URL.createObjectURL(CSVFile)
  temp_link.href = url
  temp_link.style.display = "none"
  document.body.appendChild(temp_link)
  temp_link.click()
  document.body.removeChild(temp_link)
}
