import client from "../api-client"

export default class AWSIntegrationAPI {
  static create = (body) => {
    return client("aws-integrations", { method: "POST", body })
  }

  static query = (user_id) => {
    return client(`aws-integrations?user_id=${user_id}`)
  }

  static getIntegrationsAndOptionsList = async (user_id) => {
    const integrations = await this.query(user_id)
    const integrationOptions = integrations.map((integration) => ({
      value: integration.item_id,
      label: integration.name,
    }))
    return { integrations, integrationOptions }
  }

  static update = (body) => {
    return client("aws-integrations", {
      method: "PATCH",
      body,
    })
  }

  static delete = (item_id) => {
    return client(`aws-integrations?item_id=${item_id}`, {
      method: "DELETE",
    })
  }

  static get_total_number_of_integrations = async (user_id) => {
    return client(`get-total-number-of-integrations?user_id=${user_id}`)
  }
}
