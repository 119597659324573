import React, { useContext, useMemo, useState, useEffect } from "react"
import moment from "moment"
import { UserContext } from "./UserContext"
import { useQuery } from "react-query"
import BillingDataAPI from "../utils/apis/billingData"
import AWSIntegrationAPI from "../utils/apis/awsIntegration"

const initialState = {
  billingDataStatus: "loading",
  billingData: {},
  billingDataError: "",
}

const BillingDataContext = React.createContext(initialState)

const maxDate = moment().endOf("month").toDate()

const BillingDataContextProvider = (props) => {
  const { data: userData } = useContext(UserContext),
    [viewMode, setViewMode] = useState("month"),
    [tagKey, setTagKey] = useState(),
    [isAmortized, setIsAmortized] = useState(false),
    [integration, setIntegration] = useState(userData?.view_customs?.integration),
    [selectedTagValues, setSelectedTagValues] = useState(),
    [startDate, setStartDate] = useState(moment().startOf("month").toDate()),
    [endDate, setEndDate] = useState(maxDate),
    [start, end] = useMemo(() => {
      if (viewMode === "day") {
        return [moment(startDate).format("YYYY-MM-DD"), moment(startDate).format("YYYY-MM-DD")]
      } else if (viewMode === "year") {
        return [
          moment(startDate).startOf("year").format("YYYY-MM-DD"),
          moment(startDate).endOf("year").format("YYYY-MM-DD"),
        ]
      } else {
        return [moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD")]
      }
    }, [startDate, endDate, tagKey])

  const query_part1 = `user_id=${userData.item_id}&start_date=${start}&end_date=${end}&integration=${integration}`
  const query_part2 = `&tag_key=${tagKey}&selected_tag_values=${selectedTagValues}&is_amortized=${isAmortized}`
  const billing_fetch_status =
    Boolean(userData.item_id) && Boolean(integration) && Boolean(tagKey) && Boolean(selectedTagValues)
  const billingDataQueryKey = `${query_part1}${query_part2}`,
    {
      status: billingDataStatus,
      data: billingData,
      error: billingDataError,
    } = useQuery(["billing-data", billingDataQueryKey], () => BillingDataAPI.query(billingDataQueryKey), {
      enabled: billing_fetch_status,
      retry: (_failureCount, error) =>
        error && (error.message === "BadRequestError: Subscription Expired" || error.message === "Subscription Expired")
          ? false
          : 3,
      refetchOnWindowFocus: false,
    })

  const updateBillingData = async (payload) => {
    if ("viewMode" in payload) {
      setViewMode(payload.viewMode)
    }
    if ("startDate" in payload) {
      setStartDate(payload.startDate)
    }
    if ("endDate" in payload) {
      setEndDate(payload.endDate)
    }
    if ("tagKey" in payload) {
      setTagKey(payload.tagKey)
    }
    if ("selectedTagValues" in payload) {
      setSelectedTagValues(payload.selectedTagValues)
    }
    if ("integration" in payload) {
      if (billing_fetch_status) {
        setIntegration(payload.integration)
      }
    }
    if ("isAmortized" in payload) {
      setIsAmortized(payload.isAmortized)
    }
  }

  useEffect(() => {
    if (userData.view_customs) {
      if (userData.view_customs?.integration) setIntegration(userData.view_customs?.integration)
      else setIntegration("default")
    }
  }, [userData.view_customs])

  useEffect(() => {
    if (userData.item_id) {
      AWSIntegrationAPI.get_total_number_of_integrations(userData.item_id).then((response) => {
        const total_integrations = response.total_integrations
        if (total_integrations === 0 || !userData.view_customs?.integration) {
          setIntegration("default")
        }
      })
    }

    setTagKey(userData.view_customs?.tag?.tag_name === undefined ? "all" : userData.view_customs?.tag?.tag_name)
    let tvs = ""
    if (userData.view_customs?.tag?.tag_values)
      userData.view_customs?.tag?.tag_values?.forEach((tValue) => (tvs += `${tValue.value},`))
    else tvs = "All"
    setSelectedTagValues(tvs)
  }, [userData])

  return (
    <BillingDataContext.Provider
      value={{
        billingData,
        billingDataStatus,
        billingDataError,
        updateBillingData,
      }}
    >
      {props.children}
    </BillingDataContext.Provider>
  )
}

export { BillingDataContext, BillingDataContextProvider }
